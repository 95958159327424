<template>
  <v-main>
    <v-container fluid>
      <v-row
        justify="center"
        style="min-height: 80vh;"
        :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-top: 5vh;'"
      >
        <v-col cols="12" md="5" lg="4" align-self="center">
          <v-row justify="center">
            <v-col
              cols="11"
              md="10"
              class="text-center pa-0"
              align-self="center"
              v-if="template.var.logo_only"
            >
              <v-img
                v-if="!template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo"
                width="75%"
                class="mx-auto"
              ></v-img>
              <v-img
                v-if="template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo_light"
                width="75%"
                class="mx-auto"
              ></v-img>
              <h2 class="mt-2">LOGIN</h2>
            </v-col>
            <v-col
              cols="3"
              md="2"
              class="text-center pa-0"
              align-self="center"
              v-if="!template.var.logo_only"
            >
              <v-img
                class="img-fluid"
                :src="'/img/' + template.var.logo_light"
              ></v-img>
            </v-col>
            <v-col
              cols="auto"
              align-self="center"
              class="text-left py-0"
              v-if="!template.var.logo_only"
            >
              <h2>
                {{ template.var.app_name }}
              </h2>
              <h2
                class="font-weight-light"
                :style="`color: ${template.colors.inline_primary_text}`"
              >
                LOGIN
              </h2>
            </v-col>
            <v-col cols="11" md="10">
              <v-card
                :dark="!template.light"
                :light="template.light"
                :color="template.login ? template.login.block : undefined"
                class="rounded-xl pa-4"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.email"
                      filled
                      rounded="xl"
                      dark
                      label="E-Mail"
                    ></v-text-field>
                    <v-text-field
                      v-model="form.password"
                      filled
                      rounded="xl"
                      dark
                      label="Passwort"
                      :type="show ? 'text' : 'password'"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show = !show"
                    ></v-text-field>
                    <v-switch
                      v-model="local"
                      inset
                      label="Eingeloggt bleiben"
                    ></v-switch>
                    <v-btn
                      block
                      dark
                      large
                      rounded="xl"
                      :color="template.colors.primary"
                      :style="`color: ${template.colors.inline_primary_text};`"
                      :loading="loader"
                      @click="submit()"
                    >
                      <v-icon class="mr-2">mdi-account-lock</v-icon>
                      Login
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn to="/passwort-vergessen" color="white" text>
                      Passwort vergessen?
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col align-self="center"><v-divider></v-divider></v-col>
                      <v-col
                        cols="auto"
                        align-self="center"
                        class="text-center"
                      >
                        ODER
                      </v-col>
                      <v-col align-self="center"><v-divider></v-divider></v-col>
                    </v-row>
                    <v-card
                      color="#4285F4"
                      dark
                      link
                      class="my-5 pa-0"
                      @click="g_signup()"
                    >
                      <v-row class="pa-0">
                        <v-col cols="auto" class="py-0 mr-0 pr-0">
                          <v-img src="/img/google-login.png" width="50" />
                        </v-col>
                        <v-col align-self="center">
                          <h3>Mit Google anmelden</h3>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!--<v-card
                      color="#3b5998"
                      link
                      class="my-5 pa-0"
                      @click="fb_signup()"
                    >
                      <v-row justify="center" class="pa-0 pr-2">
                        <v-col cols="12" align-self="center" class="py-2">
                          <h3>
                            <v-icon class="mx-2" large>mdi-facebook</v-icon>
                            Mit Facebook anmelden
                          </h3>
                        </v-col>
                      </v-row>
                    </v-card>-->
                    <v-card
                      color="#00acee"
                      link
                      class="my-5 pa-0"
                      @click="twitter_signup()"
                    >
                      <v-row justify="center" class="pa-0 pr-2">
                        <v-col cols="12" align-self="center" class="py-2">
                          <h3>
                            <v-icon class="mx-2" large>mdi-twitter</v-icon>
                            Mit Twitter anmelden
                          </h3>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-3">
            <v-col cols="10">
              <v-card
                :color="template.colors.blocks"
                class="rounded-xl pa-4"
                :style="`color: ${template.colors.block_text};`"
              >
                <v-row justify="center">
                  <v-col align-self="center">
                    <v-divider
                      class="my-3"
                      :style="`border-color: ${template.colors.block_text};`"
                    ></v-divider>
                  </v-col>
                  <v-col cols="auto">
                    KEINEN ACCOUNT?
                  </v-col>
                  <v-col align-self="center">
                    <v-divider
                      class="my-3"
                      :style="`border-color: ${template.colors.block_text};`"
                    ></v-divider>
                  </v-col>
                  <v-col cols="10" class="text-center">
                    <span>
                      Dann registriere jetzt deinen Verein
                    </span>
                  </v-col>
                  <v-col cols="10">
                    <v-btn
                      block
                      large
                      :color="template.colors.block_text"
                      outlined
                      to="/registrierung"
                    >
                      ZUR REGISTRIERUNG
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="10">
              <v-card
                :color="template.colors.blocks"
                class="rounded-xl pa-4"
                :style="`color: ${template.colors.block_text};`"
              >
                <v-row justify="center">
                  <v-col align-self="center">
                    <v-divider
                      class="my-3"
                      :style="`border-color: ${template.colors.block_text};`"
                    ></v-divider>
                  </v-col>
                  <v-col cols="auto">
                    PROBLEME?
                  </v-col>
                  <v-col align-self="center">
                    <v-divider
                      class="my-3"
                      :style="`border-color: ${template.colors.block_text};`"
                    ></v-divider>
                  </v-col>
                  <v-col cols="10" class="text-center">
                    <span>
                      Dann hilft dir vielleicht unsere Anleitung weiter
                    </span>
                  </v-col>
                  <v-col cols="10">
                    <v-btn
                      block
                      :color="template.colors.block_text"
                      large
                      outlined
                      :href="
                        template.var.app_url +
                        '' +
                        template.var.anleitungen.registrierung
                      "
                      target="_blank"
                    >
                      ANLEITUNG ALS PDF
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="10">
              <v-row justify="center">
                <v-col cols="10" lg="5" class="text-center">
                  <v-btn
                    text
                    :color="template.colors.inline_primary_text"
                    large
                    to="/datenschutz"
                  >
                    Datenschutz
                  </v-btn>
                </v-col>
                <v-col cols="10" lg="5" class="text-center">
                  <v-btn
                    text
                    :color="template.colors.inline_primary_text"
                    large
                    to="/impressum"
                  >
                    Impressum
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :color="template.colors.primary"
      :style="`color: ${template.colors.inline_primary_text};`"
      :dark="!template.light"
      bottom
      v-model="error"
    >
      {{ errortext }}
    </v-snackbar>
  </v-main>
</template>

<script>
//import firebase from '../../firebaseConfig'
import store from '../../store'
import router from '../../routes'
import logout from '../../auth/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      show: false,
      form: {
        email: '',
        password: '',
      },
      local: false,
      loader: false,
      error: false,
      errortext: '',
    }
  },
  computed: {
    ...mapGetters({
      template: 'template',
    }),
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.isAnonymous) {
          logout.logout()
        } else {
          router.push('/')
        }
      }
    })
  },
  methods: {
    submit() {
      this.form.email = this.form.email.replace(' ', '')
      if (this.local) {
        this.loader = true
        this.error = null
        firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .catch((err) => {
            this.loader = false
            this.error = true
            this.errortext = this.handle_error(err)
            console.log(err)
          })
      } else {
        this.loader = true
        this.error = null
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            return firebase
              .auth()
              .signInWithEmailAndPassword(this.form.email, this.form.password)
          })
          .catch((err) => {
            this.loader = false
            this.error = true
            this.errortext = this.handle_error(err)
            console.log(err)
          })
      }
    },
    g_signup() {
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch(function (error) {
          // Handle Errors here.
          this.error = error
          this.errortext = this.handle_error(error)
          console.log(error)
        })
    },
    fb_signup() {
      var provider = new firebase.auth.FacebookAuthProvider()
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch(function (error) {
          // Handle Errors here.
          this.error = error
          this.errortext = this.handle_error(error)
          console.log(error)
        })
    },
    twitter_signup() {
      var provider = new firebase.auth.TwitterAuthProvider()
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          this.error = error
          this.errortext = this.handle_error(error)
          console.log(error)
        })
    },
    handle_error(error) {
      if (error.code == 'auth/wrong-password') {
        return 'Das eingegebene Passwort ist falsch.'
      } else if (error.code == 'auth/user-not-found') {
        return 'Es existiert kein Benutzer mit dieser E-Mail Adresse.'
      } else if (error.code == 'auth/network-request-failed') {
        return 'Es ist ein Netzwerkproblem aufgetreten. Bitte versuche es zu einem späteren Zeitpunkt erneut.'
      } else if (error.code == 'auth/too-many-requests') {
        return 'Du hast zu häufig versucht dich einzuloggen und du wurdest daher vorübergehend gesperrt. Bitte versuche es zu einem späteren Zeitpunkt erneut.'
      } else if (error.code == 'auth/user-disabled') {
        return 'Dein Konto wurde deaktiviert. Bitte wende dich an den Support.'
      } else {
        return error.message
      }
    },
  },
}
</script>
